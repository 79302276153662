import React from 'react';
import classNames from 'classnames';

import SEO from '../components/SEO';

const nsBase = 'page';
const ns = `${ nsBase }-404`;

const NotFoundPage = () => {
	const rootClassnames = classNames({
		[`${ nsBase } ${ ns }`]: true,
	});

	const styles = {
		minHeight: '500px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	};

	return (
		<div className={rootClassnames} style={styles}>
			<SEO title={'404: Not found'} />
			<h1>NOT FOUND</h1>
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		</div>
	);
};

export default NotFoundPage;
